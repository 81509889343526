
const SET_USER = (state, payload) => {
  state.user = payload.data
}
const SET_VIEWED_ARTICLES = (state, payload) => {
  state.viewedArticles = payload.data
}
const SET_VIEWED_ARTICLE_READY = (state, payload) => {
  state.viewedArticleReady = payload
}
const SET_USER_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_USER,
  SET_VIEWED_ARTICLES,
  SET_VIEWED_ARTICLE_READY,
  SET_USER_STATE
}
