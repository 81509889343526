const GET_ARTICLE_GROUPS = state => {
  var data = []
  if (state.article_groups.data !== undefined) {
    state.article_groups.data.forEach(val => {
      data.push(val)
    })
  }
  return data
}

const GET_HOME_ARTICLE_GROUPS = state => {
  var data = []
  if (state.home.article_groups.data !== undefined) {
    state.home.article_groups.data.forEach(val => {
      data.push(val)
    })
  }
  return data
}

const GET_ARTICLE_GROUP_TOTAL_PAGE = state => {
  return state.article_groups.total_page
}
const SELECT_ARTICLE_GROUP = state => {
  var data = []
  if (state.article_groups.data !== undefined) {
    state.article_groups.data.forEach(val => {
      data.push({ id: val.id, group_name: val.group_name })
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}

const GET_ARTICLE_GROUP = state => {
  return state.article_group
}

const ARTICLE_GROUP_READY = state => {
  return state.ready
}

const HOME_ARTICLE_GROUP_READY = state => {
  return state.home.ready
}

const HOME_ARTICLE_GROUP_EXPIRY = state => {
  return state.home.expired_at
}

export default {
  GET_ARTICLE_GROUPS,
  ARTICLE_GROUP_READY,
  GET_ARTICLE_GROUP,
  SELECT_ARTICLE_GROUP,
  GET_ARTICLE_GROUP_TOTAL_PAGE,
  GET_HOME_ARTICLE_GROUPS,
  HOME_ARTICLE_GROUP_READY,
  HOME_ARTICLE_GROUP_EXPIRY
}
