<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layout () {
      return (this.$route.meta.layout || 'blank') + '-layout'
    }
  },
  created () {
    this.isLoggedIn = localStorage.getItem('access_token') !== null
  },
  data () {
    return {
      isLoggedIn: false
    }
  }
}
</script>
