<template>
  <MenuDropdown :hold-on-click="holdOnClick">
    <template v-slot:toggler>
      <slot></slot>
    </template>
    <MenuDropdownContent>
      <slot name="content"></slot>
    </MenuDropdownContent>
  </MenuDropdown>
</template>

<script>
import MenuDropdown from '@/components/Menu/MenuDropdown.vue'
import MenuDropdownContent from '@/components/Menu/MenuDropdownContent.vue'

export default {
  components: {
    MenuDropdown,
    MenuDropdownContent
  },
  props: {
    holdOnClick: {
      type: Boolean,
      default () {
        return false
      }
    }
  }
}
</script>
