<template>
  <modal
    :classes="'rounded'"
    :min-width="450"
    :min-height="600"
    :width="width"
    height="auto"
    :scrollable="true"
    :reset="true"
    :name="'modal-' + assignedName"
    @closed="handleModalClose()"
  >
    <slot />
  </modal>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default () {
        return null
      }
    },
    width: {
      default () {
        return '100%'
      }
    },
    show: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      assignedName: ''
    }
  },
  watch: {
    show: {
      handler (value) {
        if (value === false) {
          this.hideModal()
        }
        this.showModal()
      }
    }
  },
  mounted () {
    this.assignedName = this.name || this.getRandomInt()
  },
  methods: {
    showModal () {
      this.$modal.show('modal-' + this.assignedName)
    },
    hideModal () {
      this.$modal.hide('modal-' + this.assignedName)
    },
    handleModalClose () {
      this.$emit('update:show', false)
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  }
}
</script>

<style scoped></style>
