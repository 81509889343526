const SET_CATEGORIES = (state, payload) => {
  state.categories = payload
}
const SET_CATEGORY = (state, payload) => {
  state.category = payload.data
}
const SET_CATEGORY_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_CATEGORY,
  SET_CATEGORIES,
  SET_CATEGORY_STATE
}
