import request from '@/utils/axios'

export default {
  viewedArticle: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      // commit('SET_ARTICLE_STATE', false)
      var url = '/v1/client/analytic/viewed-articles'
      request().post(url, data)
        .then(() => { })
        .catch((e) => { reject(e) })
    })
  },
  oneMinuteViewedArticle: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      // commit('SET_ARTICLE_STATE', false)
      var url = '/v1/client/analytic/one-minutes-viewed-articles'
      request().post(url, data)
        .then(() => { })
        .catch(() => { })
    })
  }
}
