import Vue from 'vue'
import Vuex from 'vuex'
import categoryModules from './category'
import articleModules from './article'
import writterModules from './writter'
import articleGroupModules from './articleGroup'
import userModules from './user'
import analyticModules from './analytic'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    category: categoryModules,
    article: articleModules,
    writter: writterModules,
    articleGroup: articleGroupModules,
    user: userModules,
    analytic: analyticModules
  }
})
