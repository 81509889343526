<template>
  <div
    class="mt-36 lg:px-40 md:px-10 px-0 py-16 flex md:flex-row flex-col w-full border-t border-gray-100 max-width-algo"
  >
    <div class="md:flex-none flex-grow md:w-1/6 w-full py-3 text-center">
    <img :src="require('@/assets/algovision-full-logo-small.png')" style="max-height:30px" class="mx-auto" />
      <!--<span class="text-headline-5 font-semibold">Algovision</span>-->
    </div>
    <div class="flex-auto"></div>
    <div
      class="md:flex-none flex-grow py-5 flex md:flex-row flex-col md:gap-x-6 gap-0 justify-between text-center"
    >
      <a class="md:py-0 py-2 text-body-1 text-gray-900" href="#">
        Help
      </a>
      <a class="md:py-0 py-2 text-body-1 text-gray-900" href="#">
        Contact
      </a>
      <a class="md:py-0 py-2 text-body-1 text-gray-900" href="#">
        Terms of Service
      </a>
      <a class="md:py-0 py-2 text-body-1 text-gray-900" href="#">
        Privacy Policy
      </a>
    </div>
    <div v-show="scrollDistance > 100" class="fixed bottom-16 right-12 z-50">
      <ButtonSolidCircle class="p-6 " @click="scrollToTop()">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 19V7.82998L17.88 12.71C18.27 13.1 18.91 13.1 19.3 12.71C19.69 12.32 19.69 11.69 19.3 11.3L12.71 4.70998C12.32 4.31998 11.69 4.31998 11.3 4.70998L4.69997 11.29C4.30997 11.68 4.30997 12.31 4.69997 12.7C5.08997 13.09 5.71997 13.09 6.10997 12.7L11 7.82998V19C11 19.55 11.45 20 12 20C12.55 20 13 19.55 13 19Z"
            fill="white"
          />
        </svg>
      </ButtonSolidCircle>
    </div>
  </div>
</template>

<script>
import ButtonSolidCircle from '@/components/Buttons/ButtonSolidCircle.vue'

export default {
  components: {
    ButtonSolidCircle
  },
  data () {
    return {
      scrollDistance: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll (e) {
      this.scrollDistance =
        window.top.scrollY /* or: e.target.documentElement.scrollTop */
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>
