const GET_USER = state => {
  return state.user
}

const GET_VIEWED_ARTICLES = state => {
  return state.viewedArticles
}
const VIEWED_ARTICLES_READY = state => {
  return state.viewedArticleReady
}

const USER_READY = state => {
  return state.ready
}

export default {
  USER_READY,
  GET_VIEWED_ARTICLES,
  VIEWED_ARTICLES_READY,
  GET_USER
}
