<template>
  <div
    class="md:border-none border-b border-background-100 md:pb-0 pb-4"
    :class="{
      'pt-12 w-72':
        (isFullDisplay() || isExcerptDisplay()) &&
        !isResponsive() &&
        isCarousel(),
      'md:w-1/3 w-full pt-12':
        (isFullDisplay() || isExcerptDisplay()) &&
        !isResponsive() &&
        !isCarousel(),
      'md:w-1/3 w-full md:block flex flex-row-reverse md:pt-12 pt-6':
        isFullDisplay() && isResponsive() && !isCarousel()
    }"
  >
    <template v-if="isFullDisplay()">
      <div
        :class="{
          'w-full h-48': !isResponsive(),
          'md:w-full md:h-48 w-2/6 h-28': isResponsive()
        }"
      >
        <router-link
          :to="{ name: 'route.article', params: { slug: getSlug() } }"
          tag="a"
        >
          <img
            class="object-cover w-full h-full"
            :src="getHeaderImageSource()"
            alt=""
          />
        </router-link>
      </div>
      <div
        :class="{
          'w-full': !isResponsive(),
          'md:w-full w-4/6': isResponsive()
        }"
      >
        <div
          :class="{
            'pt-4': !isResponsive(),
            'md:pt-4 pt-0 text-caption ': isResponsive()
          }"
        >
          {{ getCategory() }}
        </div>
        <div
          class="font-medium pr-2 pt-2 pb-4"
          :class="{
            'text-headline-6': !isResponsive(),
            'md:text-headline-6 text-subtitle-1': isResponsive()
          }"
        >
          <router-link
            :to="{ name: 'route.article', params: { slug: getSlug() } }"
            tag="a"
            :class="{
              'overflow-ellipsis': isResponsive()
            }"
          >
            {{ getTitle() }}
          </router-link>
        </div>
        <div class="flex flex-row">
          <div class="rounded-full xl:w-auto xl:h-auto w-8 h-8 xl:my-0 my-2">
            <router-link
              :to="{
                name: 'route.author',
                params: { username: getAuthorUsername() }
              }"
              tag="a"
            >
              <!-- <img
                v-if="isAuthorHasThumbnail()"
                class="object-cover w-full h-full"
                :src="getAuthorAvatar()"
                alt=""
              /> -->
              <img
                class="object-cover w-full h-full rounded-full writter-svg"
                src="@/assets/img/user-default-small.webp"
                alt=""
              />
              <!-- <svg
                v-else
                class="w-full h-full"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#E0E0E0" />
                  <mask
                    id="mask0"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="48"
                    height="48"
                  >
                    <circle cx="24" cy="24" r="24" fill="#E0F1F2" />
                  </mask>
                  <g mask="url(#mask0)">
                    <path
                      d="M24.375 36.1541C11.7619 36.1541 1.5 46.416 1.5 59.029C1.5 59.578 1.9456 60.0236 2.4946 60.0236H46.2554C46.8044 60.0236 47.25 59.578 47.25 59.029C47.25 46.4159 36.9881 36.1541 24.375 36.1541Z"
                      fill="#FAFAFA"
                    />
                    <path
                      d="M24.3746 33.8804C30.4167 33.8804 35.3148 28.9823 35.3148 22.9402C35.3148 16.8981 30.4167 12 24.3746 12C18.3325 12 13.4344 16.8981 13.4344 22.9402C13.4344 28.9823 18.3325 33.8804 24.3746 33.8804Z"
                      fill="#FAFAFA"
                    />
                  </g>
                </svg> -->

            </router-link>
          </div>
          <div class="px-4">
            <span class="block font-subtitle-2 lg:text-base text-caption">
              <router-link
                :to="{
                  name: 'route.author',
                  params: { username: getAuthorUsername() }
                }"
                tag="a"
              >
                {{ getAuthorName() }}
              </router-link>
            </span>
            <span class="block text-gray-500 lg:text-base text-caption">
              {{ getPublishedDate() }}
            </span>
          </div>
          <div class="flex-grow px-4 py-3" >
            <span class="inline-block align-middle float-right" v-if="isLoggedIn() && !hideBookmark" @click="changeSaveArticleState()">
              <svg
                v-if="!isBookmarked()"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V6C7 5.45 7.45 5 8 5H16C16.55 5 17 5.45 17 6V18Z"
                  fill="black"
                />
              </svg>
              <svg
                v-if="isBookmarked()"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="isExcerptDisplay()">
      <div class="flex flex-row">
        <div class="rounded-full lg:w-auto lg:h-auto w-8 h-8">
          <router-link
            :to="{
              name: 'route.author',
              params: { username: getAuthorUsername() }
            }"
            tag="a"
          >
            <img
              class="object-cover w-full h-full rounded-full writter-svg"
              src="@/assets/img/user-default-small.webp"
              alt=""
            />
            <!-- <svg
                v-else
                class="w-full h-full"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#E0E0E0" />
                  <mask
                    id="mask0"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="48"
                    height="48"
                  >
                    <circle cx="24" cy="24" r="24" fill="#E0F1F2" />
                  </mask>
                  <g mask="url(#mask0)">
                    <path
                      d="M24.375 36.1541C11.7619 36.1541 1.5 46.416 1.5 59.029C1.5 59.578 1.9456 60.0236 2.4946 60.0236H46.2554C46.8044 60.0236 47.25 59.578 47.25 59.029C47.25 46.4159 36.9881 36.1541 24.375 36.1541Z"
                      fill="#FAFAFA"
                    />
                    <path
                      d="M24.3746 33.8804C30.4167 33.8804 35.3148 28.9823 35.3148 22.9402C35.3148 16.8981 30.4167 12 24.3746 12C18.3325 12 13.4344 16.8981 13.4344 22.9402C13.4344 28.9823 18.3325 33.8804 24.3746 33.8804Z"
                      fill="#FAFAFA"
                    />
                  </g>
                </svg> -->
          </router-link>
        </div>
        <div class="px-4">
          <span class="block font-subtitle-2">
            <router-link
              :to="{
                name: 'route.author',
                params: { username: getAuthorUsername() }
              }"
              tag="a"
            >
              {{ getAuthorName() }}
            </router-link>
          </span>
          <span class="block text-gray-500">
            {{ getPublishedDate() }}
          </span>
        </div>
      </div>
      <div class="font-medium text-headline-5 pr-2 py-2">
        <router-link
          :to="{ name: 'route.article', params: { slug: getSlug() } }"
          tag="a"
        >
          {{ getTitle() }}
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    slug: {
      type: String
    },
    imageSrc: {
      type: String,
      default () {
        return null
      }
    },
    category: {
      type: String
    },
    publishedDate: {
      type: String
    },
    author: {
      type: Object
    },
    full: {
      type: Boolean,
      default () {
        return true
      }
    },
    hideBookmark: {
      type: Boolean,
      default () {
        return false
      }
    },
    excerpt: {
      type: Boolean,
      default () {
        return false
      }
    },
    bookmarked: {
      type: Boolean,
      default () {
        return false
      }
    },
    responsive: {
      type: Boolean,
      default () {
        return false
      }
    },
    carousel: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    getTitle () {
      if (
        this.isFullDisplay() &&
        this.isResponsive() &&
        `${this.title}`.length > 50
      ) {
        return `${this.title}`.substring(0, 29) + '...'
      }
      return this.title
    },
    getHeaderImageSource () {
      return this.imageSrc
    },
    getCategory () {
      return this.category
    },
    getSlug () {
      return this.slug || ''
    },
    getAuthorName () {
      return this.author.fullname
    },
    isLoggedIn () {
      return localStorage.getItem('access_token') !== null
    },
    getAuthorUsername () {
      return this.author.fullname.replace(/ /g, '-')
    },
    getAuthorAvatar () {
      return this.author.avatar
    },
    getPublishedDate () {
      return this.publishedDate
    },
    isFullDisplay () {
      return this.full && !this.excerpt
    },
    isExcerptDisplay () {
      return this.excerpt
    },
    isBookmarked () {
      return this.bookmarked
    },
    isResponsive () {
      return this.responsive
    },
    isCarousel () {
      return this.carousel
    },
    isAuthorHasThumbnail () {
      return this.author.avatar !== undefined
    },
    changeSaveArticleState () {
      this.$emit('state-toggle', !this.isBookmarked())
    }

  }
}
</script>
