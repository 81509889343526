import request from '@/utils/axios'

const urlGetTransform = function (baseurl, payload) {
  var url = baseurl
  url += '?limit=' + (payload.limit !== undefined ? payload.limit : '10')
  url += '&page=' + payload.page
  // url += '&start_date=' + payload.range.start
  // url += '&end_date=' + payload.range.end
  if (payload.filter !== undefined && payload.filter.target !== undefined && payload.filter.sort !== undefined) {
    url += '&filter_target=' + payload.filter.target
    url += '&filter_sort=' + payload.filter.sort
  }
  if (payload.query_tag !== undefined && payload.query_value !== undefined) {
    url += '&query_tag=' + payload.query_tag
    url += '&query_value=' + payload.query_value
  }
  if (payload.fullname !== undefined) {
    url += '&writter=' + payload.fullname
  }
  return url
}

export default {
  createInit: ({ commit }) => {
    commit('SET_ARTICLE_STATE', false)
  },
  getArticles: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      var url = urlGetTransform('/v1/client/article', data)
      request().get(url)
        .then((result) => {
          commit('SET_ARTICLES', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  getSavedArticles: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      var url = urlGetTransform('/v1/client/article/saved', data)
      request().get(url)
        .then((result) => {
          commit('SET_ARTICLES', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  getArticleByGroups: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      var url = urlGetTransform(`/v1/client/article-group/articles/${data.groupName}`, data)
      request().get(url)
        .then((result) => {
          commit('SET_ARTICLES', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  toggleSaveArticle: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      var url = '/v1/client/article/save-article'
      request().post(url, data)
        .then((result) => {
          resolve(result.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  findArticleByTitle: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      request().get(`/v1/client/article/find-by-title/${data.article_title}`, { withCredentials: true })
        .then((result) => {
          commit('SET_ARTICLE', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  },
  getArticlesByWritter: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_ARTICLE_STATE', false)
      var url = urlGetTransform('/v1/client/article/find-by-writter/', data)
      request().get(url)
        .then((result) => {
          commit('SET_ARTICLES', result.data)
          resolve(result.data)
          commit('SET_ARTICLE_STATE', true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
          commit('SET_ARTICLE_STATE', true)
        })
    })
  }
}
