import mutation from './mutation'
import action from './action'
import getter from './getter'
export default {
  namespaced: true,
  state: {
    home: {
      article_groups: [],
      ready: false,
      expired_at: ''
    },
    article_groups: [],
    article_group: {},
    ready: false
  },
  getters: getter,
  mutations: mutation,
  actions: action,
  modules: {}
}
