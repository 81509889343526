<template>
  <div
    v-if="active"
    class="origin-top-right absolute left-0 w-full bg-off-white shadow-lg z-40 overflow-y-scroll"
    :style="`max-height: ${windowHeight - 72}px;top: 72px`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  inject: ['sharedState'],
  data () {
    return {
      windowHeight: window.innerHeight
    }
  },
  computed: {
    active () {
      return this.sharedState.active
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onClickOutside () {
      this.$emit('click-outside')
    },
    onResize () {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>
