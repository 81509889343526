const GET_ARTICLES = state => {
  var data = []
  if (state.articles.data !== undefined) {
    state.articles.data.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_ARTICLE_TOTAL_PAGE = state => {
  return state.articles.total_page
}
const SELECT_ARTICLE = state => {
  var data = []
  if (state.articles.data !== undefined) {
    state.articles.data.forEach(val => {
      data.push({ id: val.id, article_title: val.article_title })
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_ARTICLE = state => {
  return state.article
}

const ARTICLE_READY = state => {
  return state.ready
}

export default {
  GET_ARTICLES,
  ARTICLE_READY,
  GET_ARTICLE,
  SELECT_ARTICLE,
  GET_ARTICLE_TOTAL_PAGE
}
