const SET_WRITTERS = (state, payload) => {
  state.writters = payload
  console.log('test')
  console.log(payload)
}
const SET_WRITTER = (state, payload) => {
  state.writter = payload.data
}
const SET_WRITTER_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_WRITTER,
  SET_WRITTERS,
  SET_WRITTER_STATE
}
