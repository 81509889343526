<template>
  <div
    class="md:border-none border-b border-background-100 md:pb-0 pb-4"
    :class="bodyClass"
  >
    <div class="w-full load-anim bg-gray-300 h-full">
    </div>
  </div>
</template>

<style scoped>
.load-anim {
  animation: fadein 2s infinite;
}
@keyframes fadein {
  0% { opacity: 0.3}
  50%   { opacity: 1}
  100%   { opacity: 0.3}
}
</style>

<script>
export default {
  props: {
    bodyClass: {
      type: String,
      default: 'md:w-1/3 w-full pt-12'
    }
  }
}
</script>
