const SET_ARTICLE_GROUPS = (state, payload) => {
  state.article_groups = payload
  console.log('test')
  console.log(payload)
}
const SET_HOME_ARTICLE_GROUPS = (state, payload) => {
  state.home.article_groups = payload
  console.log('test')
  console.log(payload)
}
const SET_ARTICLE_GROUP = (state, payload) => {
  state.article_group = payload.data
}
const SET_ARTICLE_GROUP_STATE = (state, payload) => {
  state.ready = payload
}
const SET_HOME_ARTICLE_GROUP_STATE = (state, payload) => {
  state.home.ready = payload
}
const SET_HOME_ARTICLE_GROUP_EXPIRY = (state, payload) => {
  state.home.expired_at = payload
}

export default {
  SET_ARTICLE_GROUP,
  SET_ARTICLE_GROUPS,
  SET_ARTICLE_GROUP_STATE,
  SET_HOME_ARTICLE_GROUP_EXPIRY,
  SET_HOME_ARTICLE_GROUPS,
  SET_HOME_ARTICLE_GROUP_STATE
}
