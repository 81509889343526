<template>
  <div id="app" class="min-h-screen max-w-screen md:flex md:flex-col items-center">
    <TheHeader :ismember="isLoggedIn" ref="header" />
    <router-view :ismember="isLoggedIn" />
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  components: {
    TheHeader,
    TheFooter
  },
  mounted () {
    this.$on('login', this.showLogin)
  },
  methods: {
    showLogin () {
      console.log('opening login modal')
      this.$refs.header.showAuthModal = true
    },
    changeMemberState (val) {
      console.log('change member state triggered')
      this.isLoggedIn = val
    }
  },
  created () {
    if (localStorage.getItem('access_token')) {
      this.isLoggedIn = true
    }
    // console.log('from parent vue')
    // console.log(this.isLoggedIn)
  },
  data () {
    return {
      isLoggedIn: false
    }
  }
}
</script>
