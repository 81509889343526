import request from '@/utils/axios'

// const urlGetTransform = function (baseurl, payload) {
//   var url = baseurl
//   url += '?limit=' + (payload.limit !== undefined ? payload.limit : '10')
//   url += '&page=' + payload.page
//   // url += '&start_date=' + payload.range.start
//   // url += '&end_date=' + payload.range.end
//   url += '&filter_target=' + payload.filter.target
//   url += '&filter_sort=' + payload.filter.sort
//   if (payload.query_tag !== undefined && payload.query_value !== undefined) {
//     url += '&query_tag=' + payload.query_tag
//     url += '&query_value=' + payload.query_value
//   }
//   return url
// }

export default {
  createInit: ({ commit }) => {
    commit('SET_USER_STATE', true)
  },
  initUserProfile: ({ commit }) => {
    commit('SET_VIEWED_ARTICLE_READY', false)
    commit('SET_VIEWED_ARTICLES', { data: [] })
  },
  getProfile: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_USER_STATE', false)
      var url = '/v1/client/user/profile'
      request().get(url)
        .then((result) => {
          commit('SET_USER', result.data)
          resolve(result.data)
          commit('SET_USER_STATE', true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
          commit('SET_USER_STATE', true)
        })
    })
  },
  getViewedArticles: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('SET_VIEWED_ARTICLE_READY', false)
      var url = '/v1/client/user/viewed-articles'
      request().get(url)
        .then((result) => {
          console.log(result.data.data)
          commit('SET_VIEWED_ARTICLES', result.data)
          resolve(result.data)
          commit('SET_VIEWED_ARTICLE_READY', true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
          commit('SET_VIEWED_ARTICLE_READY', true)
        })
    })
  },
  findWritterByTitle: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      commit('SET_USER_STATE', false)
      request().get(`/v1/client/article/find-by-title/${data.article_title}`)
        .then((result) => {
          commit('SET_USER', result.data)
          resolve(result.data)
          commit('SET_USER_STATE', true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
          commit('SET_USER_STATE', true)
        })
    })
  }
}
