<template>
  <div v-click-outside="handleClickOutsideTogglerLayer">
    <div @click.prevent="toggle()" class="cursor-pointer">
      <slot name="toggler"></slot>
    </div>
    <slot />
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },
  props: {
    show: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  provide () {
    return {
      sharedState: this.sharedState
    }
  },
  data () {
    return {
      sharedState: {
        active: false
      }
    }
  },
  watch: {
    show: {
      handler (newValue) {
        this.sharedState.active = newValue
      }
    }
  },
  methods: {
    handleClickOutsideTogglerLayer () {
      this.hide()
    },
    toggle () {
      this.sharedState.active = !this.sharedState.active
      this.$emit('update:show', this.sharedState.active)
    },
    hide () {
      this.sharedState.active = false
      this.$emit('update:show', false)
    }
  }
}
</script>
