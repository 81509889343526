<template>
  <div
    class="block hover:bg-gray-200 my-1 px-4 py-1 font-medium text-gray-800 cursor-pointer"
    @click.prevent="handleClick()"
  >
    <slot />
  </div>
</template>

<script>
export default {
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>
