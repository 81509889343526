import mutation from './mutation'
import action from './action'
import getter from './getter'
export default {
  namespaced: true,
  state: {
    // articles: [],
    // article: {},
    // ready: false
  },
  getters: getter,
  mutations: mutation,
  actions: action,
  modules: {}
}
