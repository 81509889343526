<template>
  <div class="min-h-screen w-full max-width-algo">
    <flicking
    v-if="slider.homeSlider.length>0"
      ref="masthead-carousel"
      class="lg:h-1/2 h-full lg:w-3/4 w-full lg:mx-auto lg:mx-40 md:mx-auto mx-0 md:py-12 py-6"
      :options="{
        defaultIndex: 0,
        circular: true,
        autoResize: false,
        autoInit: true
      }"
      @will-change="updateSelectedSlideIndex($event)"
    >
      <div
        v-for="(slide, index) in slider.homeSlider"
        :key="index"
        @click="$router.push({ name: 'route.article', params: { slug: slide.article.article_title.replace(/ /g, '-') } })"
        class="panel w-full h-full relative md:bg-gray-400 bg-gray-300 p-1"
        style="cursor: pointer"
      >
        <img class="object-cover object-center w-full md:h-96 h-full" :src="getImage(slide.article.thumbnail)" alt="" />
        <div
        style="background-color:rgba(0,0,0,0.3)"
          class="rounded-md filtered-bg absolute xl:left-24 md:left-12 xl:bottom-24 md:bottom-12 left-0 bottom-0 text-white md:w-2/3 w-full"
        >
          <div
            class=" block text-subtitle-1 pt-4 md:px-4"
          >
            {{ slide.article.writter.fullname }}
          </div>
          <div
            class="md:px-4 rounded-sm block font-semibold xl:text-headline-3 md:text-headline-4 md:leading-10 text-headline-6"
          >
            {{ slide.article.article_title }}
          </div>
        </div>
      </div>
    </flicking>
    <div>
      <div class="mx-auto text-center">
        <span
          v-for="index in slider.homeSlider.length"
          :key="index - 1"
          class="mx-1 inline-block w-4 h-4 border border-gray rounded-full cursor-pointer"
          :class="{
            'bg-gray': isSelectedSlide(index - 1)
          }"
          @click="moveToSlide(index - 1)"
        >
        </span>
      </div>
    </div>
    <!-- today article -->
    <div class="lg:px-32 px-0 py-6" v-if="articles.todayArticles.length > 0">
      <div class="flex flex-row lg:px-8 px-4 lg:py-4 py-1">
        <div
          class="w-4/5 font-subtitle-2 text-subtitle-1 border-t border-b border-gray-100 py-5"
        >
          <span class="inline-block align-middle">
            Today Articles
          </span>
        </div>
        <div
          class="w-1/5 text-right border-t border-b border-gray-100 pt-5 pb-3"
        >
          <span class="inline-block" @click="$router.push({ name: 'route.today'})">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997C10.91 5.08997 10.91 5.71997 11.3 6.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>

      <div class="flex flex-row flex-wrap md:px-5">
        <ArticleCardLoading
          v-show="fetchState.todayArticles"
          body-class="px-3 md:w-1/3 w-full  lg:h-40 h-20"
          v-for="index in 3"
          v-bind:key="'loader' + index"
        />
        <flicking
          ref="today-article-carousel"
          class="w-full lg:hidden inline-block z-30"
          :options="{
            align: 'prev',
            circular: true,
            autoResize: true
          }"
        >
          <ArticleCard
            class="px-3"
            v-show="!fetchState.todayArticles"
            v-for="item in articles.todayArticles"
            :key="'today-article-' + item.id"
            :image-src="articleImage(item.thumbnail)"
            :slug="item.article_title.replace(/ /g, '-')"
            :title="item.article_title"
            :category="item.category.article_category_name"
            :published-date="moment(item.published_at).format('DD/MM/YYYY')"
            :author="item.writter"
            carousel
          />
        </flicking>
        <ArticleCard
          class="px-3 lg:block hidden"
          v-show="!fetchState.todayArticles"
          v-for="item in articles.todayArticles.slice(0,3)"
            :key="'today-article-' + item.id"
            :image-src="articleImage(item.thumbnail)"
            :slug="item.article_title.replace(/ /g, '-')"
            :title="item.article_title"
            :category="item.category.article_category_name"
            :published-date="moment(item.published_at).format('DD/MM/YYYY')"
            :author="item.writter"
        />
      </div>
    </div>
    <!-- latest article -->
    <div class="lg:px-32 px-0 py-6" v-if="articles.latestArticles.length > 0 && articles.todayArticles.length < 3">
      <div class="flex flex-row lg:px-8 px-4 lg:py-4 py-1">
        <div
          class="w-4/5 font-subtitle-2 text-subtitle-1 border-t border-b border-gray-100 py-5"
        >
          <span class="inline-block align-middle">
            Latest Articles
          </span>
        </div>
        <div
          class="w-1/5 text-right border-t border-b border-gray-100 pt-5 pb-3"
        >
          <span class="inline-block" @click="$router.push({ name: 'route.latest'})">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997C10.91 5.08997 10.91 5.71997 11.3 6.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>

      <div class="flex flex-row flex-wrap md:px-5">
        <ArticleCardLoading
          v-show="fetchState.latestArticles"
          body-class="px-3 md:w-1/3 w-full  lg:h-40 h-20"
          v-for="index in 3"
          v-bind:key="'loader' + index"
        />
        <flicking
          ref="today-article-carousel"
          class="w-full lg:hidden inline-block z-30"
          :options="{
            align: 'prev',
            circular: true,
            autoResize: true
          }"
        >
          <ArticleCard
            class="px-3"
            v-show="!fetchState.latestArticles"
            v-for="item in articles.latestArticles"
            :key="'today-article-' + item.id"
            :image-src="articleImage(item.thumbnail)"
            :slug="item.article_title.replace(/ /g, '-')"
            :title="item.article_title"
            :category="item.category.article_category_name"
            :published-date="moment(item.published_at).format('DD/MM/YYYY')"
            :author="item.writter"
            carousel
          />
        </flicking>
        <ArticleCard
          class="px-3 lg:block hidden"
          v-show="!fetchState.latestArticles"
          v-for="item in articles.latestArticles.slice(0,3)"
            :key="'today-article-' + item.id"
            :image-src="articleImage(item.thumbnail)"
            :slug="item.article_title.replace(/ /g, '-')"
            :title="item.article_title"
            :category="item.category.article_category_name"
            :published-date="moment(item.published_at).format('DD/MM/YYYY')"
            :author="item.writter"
        />
      </div>
    </div>
    <div class="lg:px-32 px-0 py-6" v-show="HOME_ARTICLE_GROUP_READY" v-for="(group,index) in GET_HOME_ARTICLE_GROUPS" v-bind:key="'group' + group.id">
      <div class="flex flex-row lg:px-8 px-4 lg:py-4 py-1">
        <div
          class="w-4/5 font-subtitle-2 text-subtitle-1 border-t border-b border-gray-100 py-5"
        >
          <span class="inline-block align-middle">
            {{ group.group_name }}
          </span>
        </div>
        <div
          class="w-1/5 text-right border-t border-b border-gray-100 pt-5 pb-3"
        >
          <span class="inline-block" @click="$router.push({ name: 'route.group', params: {slug: group.group_name.replace(/ /g, '-') }  })">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997C10.91 5.08997 10.91 5.71997 11.3 6.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>

      <div class="flex flex-row flex-wrap px-5">
        <ArticleCard
          class="sm:px-3"
          v-for="meta in group.metadata"
          :key="'article' + meta.id"
          :image-src="articleImage(meta.article.thumbnail)"
          :slug="meta.article.article_title.replace(/ /g,'-')"
          :title="meta.article.article_title"
          :published-date="moment(meta.article.published_at).format('DD/MM/YYYY')"
          :bookmarked="meta.article.saved_articles.length > 0"
          :author="meta.article.writter"
          :excerpt="(index % 2) < 1"
          @state-toggle="(newState) => { changeArticleSaveState(meta.article) }"
        />
      </div>
    </div>
    <div class="lg:px-32 px-0 py-6">
      <div class="flex flex-row flex-wrap md:px-5" v-if="!HOME_ARTICLE_GROUP_READY">
        <ArticleCardLoading
          v-show="fetchState.moreReadings"
          body-class="px-3 md:w-1/3 w-full  lg:h-40 h-20"
          v-for="index in 3"
          v-bind:key="'loader' + index"
        />
      </div>
    </div>
    <div class="lg:px-40 px-4 py-12" v-if="false">
      <div class="bg-background-200 h-48"></div>
    </div>
  </div>
</template>
<style scoped>
.filtered-bg {
  backdrop-filter: blur(4px);
}
</style>

<script>
// import { Flicking } from '@egjs/vue-flicking'
import ArticleCard from '@/components/Articles/ArticleCard.vue'
import ArticleCardLoading from '@/components/Articles/ArticleCardLoading.vue'
import '@egjs/vue-flicking/dist/flicking.css'
import axios from 'axios'
import { createNamespacedHelpers } from 'vuex'

const article = createNamespacedHelpers('article')
const articleGroup = createNamespacedHelpers('articleGroup')

export default {
  components: {
    // Flicking,
    ArticleCard,
    ArticleCardLoading
  },
  data () {
    return {
      articles: {
        todayArticles: [],
        latestArticles: [],
        moreReadings: []
      },
      fetchState: {
        todayArticles: true,
        latestArticles: true,
        moreReadings: true
      },
      fetchTodayOption: {
        limit: 3,
        page: 1,
        query_tag: 'time',
        query_value: 'today',
        filter: {
          target: 'id',
          sort: 'desc'
        }
      },
      fetchLatestOption: {
        limit: 3,
        page: 1,
        query_tag: 'time',
        query_value: 'latest',
        filter: {
          target: 'id',
          sort: 'desc'
        }
      },
      slider: {
        homeSlider: []
      },
      author: {
        name: 'Rama Krushna',
        username: 'ramkrush',
        avatar: require('@/assets/img/author-avatar-small-1.png')
      },
      images: [
        require('@/assets/img/article-header-preview-1.jpg'),
        require('@/assets/img/article-header-preview-2.jpg'),
        require('@/assets/img/article-header-preview-3.jpg'),
        require('@/assets/img/article-header-preview-4.jpg'),
        require('@/assets/img/article-header-preview-5.jpg'),
        require('@/assets/img/article-header-preview-6.jpg')
      ],
      selectedSlideIndex: 0
    }
  },
  computed: {
    ...articleGroup.mapGetters(['GET_HOME_ARTICLE_GROUPS', 'HOME_ARTICLE_GROUP_READY', 'HOME_ARTICLE_GROUP_EXPIRY']),
    loadedMastheadSlides () {
      return [
        {
          author: 'William Clowes',
          title:
            'Indian Virus Variant Found in Three African Countries, CDC Says',
          imageSrc: require('@/assets/img/masthead-slide-1.jpg')
        },
        {
          author: 'William Clowes',
          title:
            'Indian Virus Variant Found in Three African Countries, CDC Says',
          imageSrc: require('@/assets/img/masthead-slide-1.jpg')
        },
        {
          author: 'William Clowes',
          title:
            'Indian Virus Variant Found in Three African Countries, CDC Says',
          imageSrc: require('@/assets/img/masthead-slide-1.jpg')
        }
      ]
    }
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.$refs['masthead-carousel'].init().catch(() => {})
    // })
    const title = 'Algovision'
    document.title = title
    this.setMetaTag(title, 'Read trending stock, get insight about latest stock')
    this.$gtag.pageview(this.$route.path)
    this.getHomeSlider()
  },
  created () {
    this.getArticles({ data: this.fetchTodayOption }).then(result => {
      this.articles.todayArticles = result.data
      this.fetchState.todayArticles = false
    })
    this.getArticles({ data: this.fetchLatestOption }).then(result => {
      this.articles.latestArticles = result.data
      this.fetchState.latestArticles = false
    })
    this.fetchHomeArticleGroups()
  },
  methods: {
    ...article.mapActions(['getArticles', 'toggleSaveArticle']),
    ...articleGroup.mapActions(['getHomeArticleGroups', 'setHomeDataExpiry']),
    fetchHomeArticleGroups () {
      console.log('renew home article group')
      this.getHomeArticleGroups()
    },
    changeArticleSaveState (article) {
      this.toggleSaveArticle({ data: { article_id: article.id } }).then(result => {
        if (result.data.id !== undefined) {
          article.saved_articles.push(result.data)
        } else {
          article.saved_articles = []
        }
      })
    },
    getHomeSlider () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/client/article-slider/show/HOME-SLIDER').then(result => {
        if (result.data.data.metadata !== undefined && Array.isArray(result.data.data.metadata) && result.data.data.metadata.length > 0) {
          this.slider.homeSlider = result.data.data.metadata
        }
      })
    },
    getImage (path) {
      // var newPath = path.replace('public/', '')
      console.log('image url: ' + process.env.VUE_APP_IMAGE_URL)
      return process.env.VUE_APP_IMAGE_URL + '/' + path
    },
    getRandomImage () {
      return this.images[Math.floor(Math.random() * this.images.length)]
    },
    moveToSlide (index) {
      if (this.isSelectedSlide(index)) {
        return
      }
      this.$refs['masthead-carousel'].moveTo(index)
    },
    isSelectedSlide (index) {
      return this.selectedSlideIndex === index
    },
    updateSelectedSlideIndex ({ index }) {
      this.selectedSlideIndex = index
    }
  }
}
</script>
