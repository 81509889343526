import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import VueFlicking from '@egjs/vue-flicking'
import VModal from 'vue-js-modal'
import Vuex from 'vuex'
import VueGtag from 'vue-gtag'

import '@/assets/css/base.css'
import '@/assets/css/tailwind.css'
import '@/assets/css/flicking.css'
import 'vue-select/dist/vue-select.css'
import '@/assets/css/algovision.css'

import InputDropdownCaret from '@/components/Inputs/InputDropdownCaret.vue'

import BlankLayout from './layouts/BlankLayout.vue'
import LandingLayout from './layouts/LandingLayout.vue'
import moment from 'moment'

Vue.use(VModal)
Vue.use(Vuex)

Vue.use(VueGtag, {
  config: {
    id: 'G-XHT5JFXV0X',
    params: {
      send_page_view: false
    }
  }
})

Vue.prototype.moment = moment
Vue.prototype.articleImage = (path) => {
  return process.env.VUE_APP_IMAGE_URL + '/' + path
}

Vue.prototype.setMetaTag = (title, desc) => {
  var meta = document.createElement('meta')
  meta.name = 'description'
  meta.content = desc
  document.head.appendChild(meta)

  var ogTitle = document.createElement('meta')
  var ogDesc = document.createElement('meta')
  //  title
  ogTitle.setAttribute('property', 'og:title')
  ogTitle.content = title
  //  desc
  ogDesc.setAttribute('property', 'og:description')
  ogDesc.content = desc
  document.head.appendChild(ogDesc)
  document.head.appendChild(ogTitle)
}

vSelect.props.components.default = () => ({ OpenIndicator: InputDropdownCaret })
Vue.component('v-select', vSelect)
Vue.use(VueFlicking)

Vue.component('blank-layout', BlankLayout)
Vue.component('landing-layout', LandingLayout)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
