import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'route.home',
    component: Home,
    meta: {
      layout: 'landing'
    }
  },
  {
    path: '/today',
    name: 'route.today',
    component: () =>
      import(/* webpackChunkName: "category" */ '@/views/TodayArticle.vue'),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: '/latest',
    name: 'route.latest',
    component: () =>
      import(/* webpackChunkName: "category" */ '@/views/LatestArticle.vue'),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: '/category/:slug',
    name: 'route.category',
    component: () =>
      import(/* webpackChunkName: "category" */ '@/views/Category.vue'),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: '/nodes/:slug',
    name: 'route.group',
    component: () =>
      import(/* webpackChunkName: "category" */ '@/views/ArticleGroup.vue'),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: '/author/:username',
    name: 'route.author',
    component: () =>
      import(/* webpackChunkName: "author" */ '@/views/Author.vue'),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: '/verification',
    name: 'route.verify',
    component: () =>
      import(/* webpackChunkName: "author" */ '@/views/UserVerification.vue')
    // meta: {
    //   layout: 'landing'
    // }
  },
  {
    path: '/reset-password',
    name: 'route.reset',
    component: () =>
      import(/* webpackChunkName: "author" */ '@/views/resetPassword.vue')
    // meta: {
    //   layout: 'landing'
    // }
  },
  {
    path: '/article/:slug',
    name: 'route.article',
    component: () =>
      import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: '/profile',
    name: 'route.profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: {
      layout: 'landing'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
