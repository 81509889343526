<template>
  <div class="flex flex-col w-full md:items-center">
    <div class="lg:px-40 md:px-10 px-6  py-2 flex flex-row w-full max-width-algo">
      <div
        class="flex lg:py-5 py-3"
        :class="{
          'w-2/6': !isShowSearchBar,
          'w-1/6': isShowSearchBar && isInBreakpointRange(['xs', 'sm', 'md'])
        }"
      >
        <!-- <span
          class="md:inline-block hidden my-auto cursor-pointer"
          @click.stop="openMenuModal()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z"
              fill="#212121"
            />
          </svg>
        </span> -->
        <MenuScreen v-if="false" :show.sync="isShowCategoryList" class="my-auto">
          <template v-slot:toggler>
            <span class="md:hidden inline-block my-auto cursor-pointer">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z"
                  fill="#212121"
                />
              </svg>
            </span>
          </template>
          <MenuScreenContent>
            <div class="w-full bg-white">
              <ul class="px-6 py-3">
                <li class="py-3 w-full border-b border-gray-100">
                  <router-link
                    @click.native="hideCategoryList()"
                    tag="a"
                    :to="{ name: 'route.home' }"
                    class="text-body-1 text-gray-500 w-full"
                    active-class="text-gray-900"
                    exact
                  >
                    Home
                  </router-link>
                </li>
                <li class="py-3"
                  v-for="item in GET_CATEGORIES"
                  v-bind:key="item.id"
                >
                  <router-link
                    @click.native="hideCategoryList()"
                    tag="a"
                    :to="{ name: 'route.category', params: { slug: item.category_slug !== null ? item.category_slug : item.article_category_name } }"
                    class="text-body-1 text-gray-500"
                    active-class="font-bold text-white"
                    exact
                  >
                    {{item.article_category_name}}
                  </router-link>
                </li>
              </ul>
            </div>
          </MenuScreenContent>
        </MenuScreen>
      </div>
      <div
        class="flex-auto lg:px-8 lg:pt-5 lg:pb-4 px-0 py-3 text-center"
        :class="{
          hidden: isShowSearchBar && isInBreakpointRange(['xs', 'sm', 'md'])
        }"
      >
        <router-link tag="a" :to="{ name: 'route.home' }">
          <img :src="require('@/assets/algovision-full-logo-small.png')" class="mx-auto algo-logo" />
          <!--<span class="text-headline-5 font-semibold">Algovision</span>-->
        </router-link>
      </div>
      <div
        class="flex text-right"
        :class="{
          'py-3 w-2/6': !isShowSearchBar,
          'xl:w-4/6 w-5/6 xl:pt-3 sm:pt-2 pt-2': isShowSearchBar
        }"
      >
        <!-- <input
          ref="search-bar"
          class="inline-block align-middle py-2 border-b border-gray-100 focus:border-light-blue-accent focus:outline-none focus:rang w-4/6"
          type="text"
          v-show="isShowSearchBar"
        /> -->
        <div
          class="inline-block ml-auto my-auto"
          :class="{
            'w-2/6': isShowSearchBar
          }"
        >
          <span
            class="inline-block align-middle xl:pr-6 pr-3 cursor-pointer"
            @click.stop="toggleSearchBar()"
            v-if="false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.71 19.29L17.31 15.9C18.407 14.5025 19.0022 12.7767 19 11C19 9.41775 18.5308 7.87103 17.6518 6.55544C16.7727 5.23985 15.5233 4.21447 14.0615 3.60897C12.5997 3.00347 10.9911 2.84504 9.43928 3.15372C7.88743 3.4624 6.46197 4.22433 5.34315 5.34315C4.22433 6.46197 3.4624 7.88743 3.15372 9.43928C2.84504 10.9911 3.00347 12.5997 3.60897 14.0615C4.21447 15.5233 5.23985 16.7727 6.55544 17.6518C7.87103 18.5308 9.41775 19 11 19C12.7767 19.0022 14.5025 18.407 15.9 17.31L19.29 20.71C19.383 20.8037 19.4936 20.8781 19.6154 20.9289C19.7373 20.9797 19.868 21.0058 20 21.0058C20.132 21.0058 20.2627 20.9797 20.3846 20.9289C20.5064 20.8781 20.617 20.8037 20.71 20.71C20.8037 20.617 20.8781 20.5064 20.9289 20.3846C20.9797 20.2627 21.0058 20.132 21.0058 20C21.0058 19.868 20.9797 19.7373 20.9289 19.6154C20.8781 19.4936 20.8037 19.383 20.71 19.29ZM5 11C5 9.81332 5.3519 8.65328 6.01119 7.66658C6.67047 6.67989 7.60755 5.91085 8.7039 5.45673C9.80026 5.0026 11.0067 4.88378 12.1705 5.11529C13.3344 5.3468 14.4035 5.91825 15.2426 6.75736C16.0818 7.59648 16.6532 8.66558 16.8847 9.82946C17.1162 10.9933 16.9974 12.1997 16.5433 13.2961C16.0892 14.3925 15.3201 15.3295 14.3334 15.9888C13.3467 16.6481 12.1867 17 11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11Z"
                fill="#757575"
              />
            </svg>
          </span>
          <a
            v-show="!ismember"
            @click.prevent="openAuthModal()"
            class="xl:hidden inline-block sm:px-2 px-0 py-1 cursor-pointer"
          >
            <span class="font-button">Log in</span>
          </a>
          <ButtonSolidBox
            v-show="!ismember"
            class="xl:inline-block hidden px-14 py-3"
            color="primary"
            @click="openAuthModal()"
          >
            Sign In
          </ButtonSolidBox>
          <ButtonDropdown
            v-show="ismember"
            class="inline-block align-middle text-left"
          >
            <div class="font-button text-button text-gray">
              <div class="inline-block align-middle lg:w-10 lg:h-10 w-6 h-6">
                <svg
                  class="w-full h-full"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#E0E0E0" />
                  <mask
                    id="mask0"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="48"
                    height="48"
                  >
                    <circle cx="24" cy="24" r="24" fill="#E0F1F2" />
                  </mask>
                  <g mask="url(#mask0)">
                    <path
                      d="M24.375 36.1541C11.7619 36.1541 1.5 46.416 1.5 59.029C1.5 59.578 1.9456 60.0236 2.4946 60.0236H46.2554C46.8044 60.0236 47.25 59.578 47.25 59.029C47.25 46.4159 36.9881 36.1541 24.375 36.1541Z"
                      fill="#FAFAFA"
                    />
                    <path
                      d="M24.3746 33.8804C30.4167 33.8804 35.3148 28.9823 35.3148 22.9402C35.3148 16.8981 30.4167 12 24.3746 12C18.3325 12 13.4344 16.8981 13.4344 22.9402C13.4344 28.9823 18.3325 33.8804 24.3746 33.8804Z"
                      fill="#FAFAFA"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <template v-slot:content>
              <MenuDropdownItem
                @click="
                  $router.push({
                    name: 'route.profile'
                  })
                "
              >
                <div class="py-3">
                  <svg
                    class="inline align-middle"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.9847 15.3462C8.11707 15.3462 4.81421 15.931 4.81421 18.2729C4.81421 20.6148 8.09611 21.2205 11.9847 21.2205C15.8523 21.2205 19.1542 20.6348 19.1542 18.2938C19.1542 15.9529 15.8733 15.3462 11.9847 15.3462Z"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.9846 12.0059C14.5227 12.0059 16.5799 9.94779 16.5799 7.40969C16.5799 4.8716 14.5227 2.81445 11.9846 2.81445C9.44655 2.81445 7.38845 4.8716 7.38845 7.40969C7.37988 9.93922 9.42369 11.9973 11.9523 12.0059H11.9846Z"
                      stroke="#130F26"
                      stroke-width="1.42857"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span class="inline pl-4 align-middle">
                    Profile
                  </span>
                </div>
              </MenuDropdownItem>
              <MenuDropdownItem
                @click="() => showChangePasswordModal = true"
              >
                <div class="py-3">
                  <svg
                    class="inline align-middle"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4235 9.48027V7.33327C16.4235 4.82027 14.3855 2.78227 11.8725 2.78227C9.35949 2.77127 7.31349 4.79927 7.30249 7.31327V7.33327V9.48027"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.6832 21.2822H8.04224C5.94824 21.2822 4.25024 19.5852 4.25024 17.4902V13.2012C4.25024 11.1062 5.94824 9.40918 8.04224 9.40918H15.6832C17.7772 9.40918 19.4752 11.1062 19.4752 13.2012V17.4902C19.4752 19.5852 17.7772 21.2822 15.6832 21.2822Z"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.8629 14.2354V16.4564"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span class="inline pl-4 align-middle">
                    Password
                  </span>
                </div>
              </MenuDropdownItem>
              <MenuDropdownItem @click="setLoggedOff()">
                <div class="py-3">
                  <svg
                    class="inline align-middle"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.016 7.38948V6.45648C15.016 4.42148 13.366 2.77148 11.331 2.77148H6.45597C4.42197 2.77148 2.77197 4.42148 2.77197 6.45648V17.5865C2.77197 19.6215 4.42197 21.2715 6.45597 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.8096 12.0215H9.76855"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.8811 9.10645L21.8091 12.0214L18.8811 14.9374"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span class="inline pl-4 align-middle cursor-pointer">
                    Logout
                  </span>
                </div>
              </MenuDropdownItem>
            </template>
          </ButtonDropdown>
        </div>
      </div>
    </div>
    <!-- display MD -->
    <div
      v-if="false"
      class="xl:hidden py-5 lg:px-40 md:px-10 px-0 flex flex-row w-full border-t border-b border-gray-100 gap-x-6 justify-between overflow-y-hidden"
    >
      <flicking
        class="w-full"
        :options="{
          defaultIndex: -1,
          bound: true,
          autoResize: true
        }"
      >
        <router-link
          tag="a"
          :to="{ name: 'route.home' }"
          class="px-3 text-body-1 text-gray-500"
          active-class="text-gray-900"
          exact
        >
          Home
        </router-link>
        <router-link
        v-for="item in GET_CATEGORIES"
        v-bind:key="item.id"
        tag="a"
        :to="{ name: 'route.category', params: { slug: item.category_slug !== null ? item.category_slug : item.article_category_name } }"
        class="px-3 text-body-1 text-gray-500"
        active-class="text-gray-900"
        exact
      >
        {{item.article_category_name}}
      </router-link>
      </flicking>
    </div>
    <!-- display XL -->
    <div
      v-if="false"
      class="xl:flex hidden px-40 py-5 flex-row w-full border-t border-b border-gray-100 gap-x-6 justify-between overflow-hidden"
    >
      <router-link
        tag="a"
        :to="{ name: 'route.home' }"
        class="px-3 lg:px-0 text-body-1 text-gray-500"
        active-class="text-gray-900"
        exact
      >
        Home
      </router-link>
      <router-link
        v-for="item in GET_CATEGORIES"
        v-bind:key="item.id"
        tag="a"
        :to="{ name: 'route.category', params: { slug: item.category_slug !== null ? item.category_slug : item.article_category_name } }"
        class="px-3 lg:px-0 text-body-1 text-gray-500"
        active-class="text-gray-900"
        exact
      >
        {{item.article_category_name}}
      </router-link>
    </div>
    <div v-if="!userValidation" class="md:flex hidden lg:px-40 md:px-10 pt-5 pb-1 flex-row w-full border-t border-b border-gray-100 gap-x-2 overflow-hidden">
      <div class="my-auto">{{ requestingVerification? 'Meminta pembaharusan verifikasi' : 'Silahkan cek email anda untuk verifikasi akun anda.' }}</div>
      <ButtonSolidBox v-show="!requestingVerification" @click="requestVerification()" class="py-1">Kirim ulang verifikasi. </ButtonSolidBox>
    </div>
    <div  v-if="!userValidation" class="md:hidden px-5 pt-5 pb-1 w-full border-t border-b border-gray-100 gap-x-2 overflow-hidden">
      <div class="my-auto">{{ requestingVerification? 'Meminta pembaharusan verifikasi' : 'Silahkan cek email anda untuk verifikasi akun anda.' }}</div>
      <ButtonSolidBox  v-show="!requestingVerification" @click="requestVerification()" class="py-1 mt-2">Kirim ulang verifikasi. </ButtonSolidBox>
    </div>
    <BasicModal name="search" width="80%" :show.sync="showSearchModal">
      <div class="w-full min-h-full px-10 py-5">
        <div class="w-100 flex-row">
          <img :src="require('@/assets/algovision-full-logo-small.png')" style="max-height:30px" class="mb-5" />
          <strong>Type keyword you are looking for</strong>
        </div>
        <div class="w-100 mt-5 flex">
          <div class="w-full grid grid-cols-1 md:grid-cols-5">
            <input
              @keyup.enter="findNow()"
              ref="search-bar"
              type="text"
              class="w-full py-2 border-b-2 border-dark bg-gray-300 px-4 py-4 rounded md:col-start-1 md:col-end-5"
              placeholder="Type keyword to search"
            />
            <div class="py-1 mt-2 md:mt-0 px-4 px-2">
              <ButtonSolidBox class="md:h-full w-full">find</ButtonSolidBox>
            </div>

          </div>
        </div>
        <div class="w-100 mt-5" v-show="!searching">
          <p>Search result: </p>
        </div>
        <div class="w-100 mt-5" v-show="searching">
          <Loading :show="true" />
        </div>
        <div class="w-100 mt-5 search-result"  v-show="!searching">
          <div v-for="x in 10" :key="x" class="my-4 bg-primary-300 py-2 px-2 rounded">
            <small>test data result {{x}}</small>
          </div>
        </div>
      </div>
    </BasicModal>
    <BasicModal name="menu" width="80%" :show.sync="showMenuModal" v-if="false">
      <div class="flex flex-row w-full min-h-full">
        <div class="w-1/3 bg-background flex flex-col py-10" >
          <div class="flex">
            <img :src="require('@/assets/algovision-full-logo-small.png')" style="max-height:50px" class="pl-16 mb-5" />

          </div>
          <div class=" bg-background flex flex-row xl:flex-nowrap flex-wrap pb-24" >
            <ul class="pl-16 pr-5">
              <li class="py-3" v-show="false">
                <router-link
                  @click.native="closeMenuModal()"
                  tag="a"
                  :to="{ name: 'route.home' }"
                  class="text-body-1 text-gray-500"
                  active-class="font-bold text-white"
                  exact
                >
                  <img :src="require('@/assets/algovision-full-logo-small.png')" style="max-height:30px" class="mx-auto block" />
                </router-link>
              </li>
              <li class="py-3"
                v-for="item in GET_CATEGORIES.slice(0,7)"
                v-bind:key="item.id"
              >
                <router-link
                  @click.native="closeMenuModal()"
                  tag="a"
                  :to="{ name: 'route.category', params: { slug: item.category_slug !== null ? item.category_slug : item.article_category_name } }"
                  class="text-body-1 text-gray-500"
                  active-class="font-bold text-white"
                  exact
                >
                  {{item.article_category_name}}
                </router-link>
              </li>
            </ul>

            <ul class="xl:pl-5 xl:pr-16 pl-16">
              <li class="py-3"
                v-for="item in GET_CATEGORIES.slice(8,15)"
                v-bind:key="item.id"
              >
                <router-link
                  @click.native="closeMenuModal()"
                  tag="a"
                  :to="{ name: 'route.category', params: { slug: item.category_slug !== null ? item.category_slug : item.article_category_name } }"
                  class="text-body-1 text-gray-500"
                  active-class="font-bold text-white"
                  exact
                >
                  {{item.article_category_name}}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-2/3 flex flex-row xl:flex-nowrap flex-wrap py-24">
          <ul class="pl-16 pr-6" v-for="index in (GET_CATEGORIES.length / 5)" v-bind:key="'bigmenu' + index">
            <li class="py-3"
              v-for="item in GET_CATEGORIES.slice((0 + (5 * ( index - 1 ) )), (5 + (5 * ( index - 1 ) )))"
              v-bind:key="item.id"
            >
              <router-link
                @click.native="closeMenuModal()"
                tag="a"
                :to="{ name: 'route.category', params: { slug: item.category_slug !== null ? item.category_slug : item.article_category_name } }"
                class="text-body-1 text-gray-500"
                active-class="font-bold text-white"
                exact
              >
                {{item.article_category_name}}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </BasicModal>
    <BasicModal name="changepass" :width="authModalWidth" :show.sync="showChangePasswordModal">
      <div
        class="lg:py-10 py-3 mx-auto max-w-lg"
      >
        <div class="lg:mx-12 mx-2 pb-6 py-3">
          <p class="font-headline-6 text-headline-6 p-3 mx-6">
            Change password
          </p>
          <p class="font-light text-body-1 px-3 mx-6 text-gray-500">
            Please fill the below to continue
          </p>
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <!-- <InputTextBox class="px-6" type="email" placeholder="Email" /> -->
          <InputTextBox
            class="px-0 py-2 mx-1"
            type="password"
            placeholder="Enter your current password"
            @input="val => setOldPassword(val)"
            :hide-errors="!changePassWarning.password"
            :disabled="processing"
            warning="Please check again"
            warning-class="text-red-500"
          />
        </div>
        <div class="lg:mx-12 mx-2 px-3 pb-3">
          <!-- <InputTextBox class="px-6" type="email" placeholder="Email" /> -->
          <InputTextBox
            class="px-0 py-0 mx-1"
            type="password"
            placeholder="Enter your new password"
            @input="val => setNewPassword(val)"
            :hide-errors="!changePassWarning.new_password"
            :disabled="processing"
            warning="Please check again"
            warning-class="text-red-500"
          />
        </div>
        <div class="lg:mx-12 mx-2 px-3">
          <Loading :show="processing" />
        </div>
        <div class="lg:mx-12 mx-2 px-6">
          <small>{{changePassMessage}}</small>
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <div class="p-3 mx-3">
            <ButtonSolidBox
              class="w-full h-full"
              color="primary"
              @click="changePassword()"
            >
              Change now.
            </ButtonSolidBox>
          </div>
        </div>
      </div>
    </BasicModal>
    <BasicModal name="auth" :width="authModalWidth" :show.sync="showAuthModal">
      <div class="p-6 w-full">
        <span class="float-right cursor-pointer" @click.stop="closeAuthModal()">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.89 8.7L12 10.59L10.11 8.7C9.72 8.31 9.09 8.31 8.7 8.7C8.31 9.09 8.31 9.72 8.7 10.11L10.59 12L8.7 13.89C8.31 14.28 8.31 14.91 8.7 15.3C9.09 15.69 9.72 15.69 10.11 15.3L12 13.41L13.89 15.3C14.28 15.69 14.91 15.69 15.3 15.3C15.69 14.91 15.69 14.28 15.3 13.89L13.41 12L15.3 10.11C15.69 9.72 15.69 9.09 15.3 8.7C14.91 8.32 14.27 8.32 13.89 8.7ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
              fill="#9E9E9E"
            />
          </svg>
        </span>
      </div>

      <div
        v-show="authModalMode === 'sign-in'"
        class="lg:py-10 py-3 mx-auto max-w-lg"
      >
        <div class="lg:mx-12 mx-2 pb-6 py-3">
          <p class="font-headline-6 text-headline-6 p-3 mx-6">
            Sign in
          </p>
          <p class="font-light text-body-1 px-3 mx-6 text-gray-500">
            Please fill the below to continue
          </p>
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <!-- <InputTextBox class="px-6" type="email" placeholder="Email" /> -->
          <InputTextBox
            class="px-0 py-2 mx-1"
            type="email"
            placeholder="Enter your email"
            @input="emailValue"
            @enter-pressed="loginOnEnterPressed()"
            :hide-errors="!emailError"
            :disabled="processing"
            warning="Please check again"
            warning-class="text-red-500"
          />
          <!-- <InputTextBox class="px-6" type="password" placeholder="Password" /> -->
          <InputTextBox
            class="px-0 py-2 mx-1"
            type="password"
            placeholder="Enter your password"
            @enter-pressed="loginOnEnterPressed()"
            @input="passwordValue"
            :hide-errors="!passwordError"
            :disabled="processing"
            warning="Please check again"
            warning-class="text-red-500"
          />
        </div>
        <div class="lg:mx-12 mx-2 px-3">
          <Loading :show="processing" />
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <div class="p-3 mx-3">
            <ButtonSolidBox
              class="w-full h-full"
              color="primary"
              @click="login()"
            >
              Sign In
            </ButtonSolidBox>
            <p
              class="font-button text-button mx-auto text-center mt-3 text-gray-500 pt-8"
            >
              Dont have an account?
              <a
                class="underline text-gray-900 cursor-pointer"
                @click="setToSignUp()"
                >Sign up</a
              >
            </p>
            <p
              class="font-button text-button mx-auto text-center mt-0 text-gray-500 pt-3"
            >
              Forgot your password?
              <a
                class="underline text-gray-900 cursor-pointer"
                @click="setToReset()"
                >Reset password</a
              >
            </p>
          </div>
        </div>
      </div>
      <div
        v-show="authModalMode === 'reset'"
        class="lg:py-10 py-3 mx-auto max-w-lg"
      >
        <div class="lg:mx-12 mx-2 pb-6 py-3">
          <p class="font-headline-6 text-headline-6 p-3 mx-6">
            Reset your password
          </p>
          <p class="font-light text-body-1 px-3 mx-6 text-gray-500">
            Please fill the below to continue
          </p>
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <!-- <InputTextBox class="px-6" type="email" placeholder="Email" /> -->
          <InputTextBox
            class="px-0 py-2 mx-1"
            type="email"
            placeholder="Enter your email"
            @input="val => setResetEmailValue(val)"
            :hide-errors="!emailResetError"
            :disabled="processing"
            warning="Please check again"
            warning-class="text-red-500"
          />
        </div>
        <div class="lg:mx-12 mx-2 px-3">
          <Loading :show="processing" />
        </div>
        <div class="lg:mx-12 mx-2 px-6" v-if="forgotPasswordDone">
          <small>If your email registered, you will get an email from us to reset your password.</small>
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <div class="p-3 mx-3">
            <ButtonSolidBox
              class="w-full h-full"
              color="primary"
              @click="resetPassword()"
            >
              Reset
            </ButtonSolidBox>
            <p
              class="font-button text-button mx-auto text-center mt-3 text-gray-500 pt-8"
            >
              Remember your password?
              <a
                class="underline text-gray-900 cursor-pointer"
                @click="setToSignIn()"
                >Login now</a
              >
            </p>
          </div>
        </div>
      </div>

      <div
        v-show="authModalMode === 'sign-up'"
        class="lg:py-10 py-3 mx-auto max-w-lg"
      >
        <div class="lg:mx-12 mx-2 pb-6 py-3">
          <p class="font-headline-6 text-headline-6 p-3 mx-6">
            Sign up
          </p>
          <p class="font-light text-body-1 px-3 mx-6 text-gray-500">
            Please fill the below to continue
          </p>
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <InputTextBox
            class="px-6 py-2 mx-1"
            type="text"
            placeholder="Enter your fullname"
            @input="val => registerValue('fullname', val)"
            :hide-errors="registerWarning.fullname === ''"
            :disabled="processing"
            :warning="registerWarning.fullname"
            warning-class="text-red-500"
          />
          <InputTextBox
            class="px-6 py-2 mx-1"
            type="email"
            placeholder="Enter your email"
            @input="val => registerValue('email', val)"
            :hide-errors="registerWarning.email === ''"
            :disabled="processing"
            :warning="registerWarning.email"
            warning-class="text-red-500"
          />
          <InputTextBox
            class="px-6 py-2 mx-1"
            type="password"
            placeholder="Enter your password"
            @input="val => registerValue('password', val)"
            :hide-errors="registerWarning.password === ''"
            :disabled="processing"
            :warning="registerWarning.password"
            warning-class="text-red-500"
          />
          <InputTextBox
            class="px-6 py-2 mx-1"
            type="tel"
            placeholder="Enter your phone"
            @input="val => registerValue('phone', val)"
            :hide-errors="registerWarning.phone === ''"
            :disabled="processing"
            :warning="registerWarning.phone"
            warning-class="text-red-500"
          />
          <InputDropdown
            class="px-6 mx-1"
            placeholder="Gender"
            label="Gender"
            :options="['Male', 'Female']"
            :warning="registerWarning.gender"
            warningClass="text-red-500"
            @input="val => registerValue('gender', val)"
          ></InputDropdown>
          <!-- <InputDropdown
            class="px-6"
            :options="['Male', 'Female']"
            placeholder="Gender"
          ></InputDropdown> -->
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <div class="p-3 mx-3 text-red-500">
            {{ isRegisterFailed ? 'Please check all the field again':'' }}
          </div>
        </div>
        <div class="lg:mx-12 mx-2 px-3">
          <Loading :show="processing" />
        </div>
        <div class="lg:mx-12 mx-2 p-3">
          <div class="p-3 mx-3">
            <ButtonSolidBox
              class="w-full h-full"
              color="primary"
              @click="processRegister()"
            >
              Sign Up
            </ButtonSolidBox>
            <p
              class="font-button text-button mx-auto text-center mt-3 text-gray-500 pt-8"
            >
              Have an account?
              <a
                class="underline text-gray-900 cursor-pointer"
                @click="setToSignIn()"
                >Sign in</a
              >
            </p>
          </div>
        </div>
      </div>
    </BasicModal>
  </div>
</template>

<script>
// import { Flicking } from '@egjs/vue-flicking'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputDropdown from '@/components/Inputs/InputDropdown.vue'
import BasicModal from '@/components/Modals/BasicModal.vue'
import MenuScreen from '@/components/Menu/MenuScreen.vue'
import MenuScreenContent from '@/components/Menu/MenuScreenContent.vue'
import ButtonDropdown from '@/components/Buttons/ButtonDropdown.vue'
import MenuDropdownItem from '@/components/Menu/MenuDropdownItem.vue'
import Loading from '@/components/Loading.vue'
import { createNamespacedHelpers } from 'vuex'

import axios from 'axios'
import request from '@/utils/axios'

const articleCategories = createNamespacedHelpers('category')

export default {
  components: {
    // Flicking,
    ButtonSolidBox,
    InputTextBox,
    InputDropdown,
    BasicModal,
    MenuScreen,
    MenuScreenContent,
    ButtonDropdown,
    MenuDropdownItem,
    Loading
  },
  data () {
    return {
      userValidation: true,
      requestingVerification: false,
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      processing: false,
      isShowCategoryList: false,
      isShowSearchBar: false,
      screenWidth: window.innerWidth,
      showMenuModal: false,
      showAuthModal: false,
      showSearchModal: false,
      searching: false,
      showChangePasswordModal: false,
      authModalMode: 'sign-in',
      isLoggedOn: false,
      accessToken: null,
      registerData: {
        fullname: '',
        email: '',
        password: '',
        gender: '',
        phone: ''
      },
      registerWarning: {
        fullname: '',
        email: '',
        password: '',
        gender: '',
        phone: ''
      },
      changePass: {
        password: '',
        new_password: ''
      },
      changePassMessage: '',
      changePassWarning: {
        password: false,
        new_password: false
      },
      emailResetValue: '',
      emailResetError: false,
      forgotPasswordDone: false,
      isRegisterFailed: false,
      fetchParam: {
        filter: {
          target: 'created_at',
          sort: 'asc'
        },
        currentPage: 1
      }
    }
  },
  props: {
    ismember: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    ...articleCategories.mapGetters(['GET_CATEGORIES', 'CATEGORY_READY']),
    authModalWidth () {
      if (
        ['xl', '2xl'].findIndex(
          breakpoint => breakpoint === this.getCurrentBreakpoint()
        ) !== -1
      ) {
        return '40%'
      }
      if (
        ['sm', 'md', 'lg'].findIndex(
          breakpoint => breakpoint === this.getCurrentBreakpoint()
        ) !== -1
      ) {
        return '50%'
      }
      return '95%'
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.isLoggedOn = this.$parent.isLoggedIn
    // this.userState()
  },
  created () {
    this.getCategories({ data: this.fetchParam })
    if (localStorage.getItem('access_token') !== null) {
      // console.log('sync')
      if (localStorage.getItem('user_validation') === null) {
        this.userSync()
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...articleCategories.mapActions(['getCategories']),
    loginOnEnterPressed () {
      this.login()
    },
    findNow () {
      this.searching = true
    },
    userState () {
      this.isLoggedOn = this.$parent.isLoggedOn
    },
    setOldPassword (val) {
      this.changePass.password = val
    },
    setNewPassword (val) {
      this.changePass.new_password = val
    },
    setResetEmailValue (val) {
      this.emailResetValue = val
    },
    emailValue (val) {
      // console.log(val)
      this.email = val
    },
    passwordValue (val) {
      this.password = val
    },
    userSync () {
      request().get(process.env.VUE_APP_API_URL + '/v1/client/user/sync')
        .then(async (result) => {
          this.userValidation = result.data.validation
          if (this.userValidation === true) {
            localStorage.setItem('user_validation', true)
          }
          // console.log(`user validation ${this.userValidation}`)
        })
    },
    requestVerification () {
      this.requestingVerification = true
      setTimeout(() => {
        request().get(process.env.VUE_APP_API_URL + '/v1/client/user/resend-verification')
          .then(async () => {
            this.requestingVerification = false
          })
      }, 2000)
    },
    resetPassword () {
      if (this.emailResetValue !== '') {
        this.processing = true
        axios.post(process.env.VUE_APP_API_URL + '/v1/client/user/forgot', { email: this.emailResetValue })
          .then(async (result) => {
            this.processing = false
            this.forgotPasswordDone = true
          })
          .catch((err) => {
            this.isRegisterFailed = true
            if (err.response) {
              // console.log(err.response)
            } else {
              // console.log(err)
            }
            this.processing = false
          })
      }
    },
    changePassword () {
      var validate = true
      Object.keys(this.changePass).forEach(key => {
        if (this.changePass[key] === '') {
          this.changePassWarning[key] = true
          validate = false
        } else {
          this.changePassWarning[key] = false
        }
      })

      if (validate) {
        if (this.changePass.password === this.changePass.new_password) {
          this.changePassMessage = 'Old password cannot be similar to new password.'
          return
        }
      } else {
        return
      }

      this.processing = true
      request().post('/v1/client/user/change-password', this.changePass)
        .then(async (result) => {
          if (result.data.data === 'ok') {
            this.changePassMessage = 'Password successfully changed.'
          } else {
            this.changePassMessage = this.result.data.error
          }
          this.processing = false
        })
        .catch((err) => {
          console.log(err)
          this.isRegisterFailed = true
          this.changePassMessage = 'Something wrong happened.'
          this.processing = false
        })
    },
    processRegister () {
      if (this.validate()) {
        this.isRegisterFailed = false
        this.processing = true
        axios.post(process.env.VUE_APP_API_URL + '/v1/client/user/create', this.registerData)
          .then(async (result) => {
            this.processing = false
            if (result.data.data.access_token !== undefined) {
              localStorage.setItem('access_token', result.data.data.access_token)
              // this.$router.push({ name: 'route.dashboard.home' })
              this.$gtag.event('event', {
                event_category: 'UserAction',
                event_label: 'Registration'
              })
              this.resetRegisField()
              this.setLoggedOn()
              this.closeAuthModal()
              this.processing = false
            } else {
              this.isRegisterFailed = true
            }
          })
          .catch((err) => {
            this.isRegisterFailed = true
            if (err.response) {
              // console.log(err.response)
            } else {
              // console.log(err)
            }
            this.processing = false
          })
      }
    },
    registerValue (target, val) {
      if (val === '') {
        this.registerWarning[target] = 'This field is required'
      } else {
        if (this.registerWarning[target] !== '') {
          this.registerWarning[target] = ''
        }
        this.registerData[target] = val
      }
    },
    validate () {
      var validation = true
      Object.keys(this.registerData).forEach(val => {
        if (this.registerData[val] === '') {
          validation = false
          this.registerWarning[val] = 'This field is required'
        }
      })
      return validation
    },
    login () {
      if (this.email !== '' && this.password !== '') {
        this.emailError = false
        this.passwordError = false
        this.processing = true
        axios.post(process.env.VUE_APP_API_URL + '/v1/client/user/auth', { email: this.email, password: this.password })
          .then(async (result) => {
            if (result.data.data.access_token !== undefined) {
              localStorage.setItem('access_token', result.data.data.access_token)
              // this.$router.push({ name: 'route.dashboard.home' })
              this.verifying = false
              this.$gtag.event('event', {
                event_category: 'UserAction',
                event_label: 'Login'
              })
              this.setLoggedOn()
              this.closeAuthModal()
              this.processing = false
            } else {
              this.processing = false
              this.emailError = true
              this.passwordError = true
            }
            // this.processing = false
          })
          .catch((err) => {
            this.processing = false
            if (err.response) {
              // console.log(err.response)
            } else {
              // console.log(err)
            }
            this.processing = false
            this.emailError = true
            this.passwordError = true
          })
      } else {
        this.emailError = (this.email === '')
        // console.log('email error: ' + this.emailError)
        this.passwordError = (this.password === '')
        // console.log('password error: ' + this.emailError)
      }
    },
    resetRegisField () {
      this.registerData = {
        fullname: '',
        email: '',
        password: '',
        gender: '',
        phone: ''
      }
    },
    setToSignIn () {
      this.authModalMode = 'sign-in'
    },
    setToReset () {
      this.authModalMode = 'reset'
    },
    setToSignUp () {
      this.authModalMode = 'sign-up'
    },
    toggleSearchBar () {
      // this.isShowSearchBar = !this.isShowSearchBar
      this.showSearchModal = !this.showSearchModal
      // if (this.isShowSearchBar) {
      //   this.$nextTick(() => {
      //     this.$refs['search-bar'].focus()
      //   })
      // }
    },
    showLogin (state) {
      console.log('opening login modal')
      this.showAuthModal = state
    },
    openAuthModal () {
      this.isLoggedOn = localStorage.getItem('isLoggedOn') || false

      if (!this.isLoggedOn) {
        this.showAuthModal = true
      }
    },
    closeAuthModal () {
      this.showAuthModal = false
    },
    openMenuModal () {
      this.showMenuModal = true
    },
    closeMenuModal () {
      this.showMenuModal = false
    },
    setLoggedOn () {
      localStorage.setItem('isLoggedOn', true)
      this.$parent.changeMemberState(true)
      // this.isLoggedOn = true
      // location.reload()
    },
    setLoggedOff () {
      localStorage.clear()
      this.$parent.changeMemberState(false)
      // this.isLoggedOn = false
      // location.reload()
    },
    // login () {
    //   this.setLoggedOn()
    //   this.closeAuthModal()
    // },
    getCurrentBreakpoint () {
      if (this.screenWidth > 1536) {
        return '2xl'
      }
      if (this.screenWidth > 1280) {
        return 'xl'
      }
      if (this.screenWidth > 1024) {
        return 'lg'
      }
      if (this.screenWidth > 768) {
        return 'md'
      }
      if (this.screenWidth > 640) {
        return 'sm'
      }
      return 'xs'
    },
    isInBreakpointRange (range) {
      return (
        range.findIndex(
          breakpoint => breakpoint === this.getCurrentBreakpoint()
        ) !== -1
      )
    },
    onResize () {
      this.$set(this, 'screenWidth', window.innerWidth)
    },
    hideCategoryList () {
      this.isShowCategoryList = false
    }
  }
}
</script>
