import axios from 'axios'

const req = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

req.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

const request = () => {
  req.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
  return req
}

export default request
