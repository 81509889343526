<template>
  <div class="min-h-16">
    <div
      class="h-full pl-2 py-2"
      :class="{
        'bg-background-50 border border-gray-100 rounded ': !isFlat()
      }"
    >
      <v-select
        ref="select-dropdown"
        class="input-dropdown text-right my-auto"
        :label="label"
        :options="options"
        :value="value"
        @input="updateInput($event)"
        :placeholder="placeholder"
        :disabled="disabled"
      ></v-select>
    </div>
    <div
      v-if="!isErrorHidden()"
      class="font-caption text-caption px-4 py-2"
      :class="warningClass"
    >
      {{warning}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    options: {
      type: Array,
      default () {
        return []
      }
    },
    warning: {
      type: String,
      default () {
        return ''
      }
    },
    warningClass: {
      type: String,
      default () {
        return 'text-gray-500'
      }
    },
    placeholder: {
      type: String,
      default () {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    hideErrors: {
      type: Boolean,
      default () {
        return false
      }
    },
    flat: {
      type: Boolean,
      default () {
        return false
      }
    },
    label: {
      type: String,
      default () {
        return ''
      }
    }
  },
  methods: {
    isErrorHidden () {
      return this.hideErrors
    },
    isFlat () {
      return this.flat
    },
    updateInput (value) {
      // console.log(value)
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
.input-dropdown >>> .vs__search::placeholder,
.input-dropdown >>> .vs__dropdown-toggle,
.input-dropdown >>> .vs__dropdown-menu {
  @apply border-none font-button text-button text-gray-500;
}
.input-dropdown >>> .vs__clear {
  @apply hidden;
}
.input-dropdown.vs--disabled >>> .vs__dropdown-toggle,
.input-dropdown.vs--disabled >>> .vs__search,
.input-dropdown.vs--disabled >>> .vs__open-indicator {
  @apply bg-transparent;
}
</style>
