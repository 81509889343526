var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md:border-none border-b border-background-100 md:pb-0 pb-4",class:{
    'pt-12 w-72':
      (_vm.isFullDisplay() || _vm.isExcerptDisplay()) &&
      !_vm.isResponsive() &&
      _vm.isCarousel(),
    'md:w-1/3 w-full pt-12':
      (_vm.isFullDisplay() || _vm.isExcerptDisplay()) &&
      !_vm.isResponsive() &&
      !_vm.isCarousel(),
    'md:w-1/3 w-full md:block flex flex-row-reverse md:pt-12 pt-6':
      _vm.isFullDisplay() && _vm.isResponsive() && !_vm.isCarousel()
  }},[(_vm.isFullDisplay())?[_c('div',{class:{
        'w-full h-48': !_vm.isResponsive(),
        'md:w-full md:h-48 w-2/6 h-28': _vm.isResponsive()
      }},[_c('router-link',{attrs:{"to":{ name: 'route.article', params: { slug: _vm.getSlug() } },"tag":"a"}},[_c('img',{staticClass:"object-cover w-full h-full",attrs:{"src":_vm.getHeaderImageSource(),"alt":""}})])],1),_c('div',{class:{
        'w-full': !_vm.isResponsive(),
        'md:w-full w-4/6': _vm.isResponsive()
      }},[_c('div',{class:{
          'pt-4': !_vm.isResponsive(),
          'md:pt-4 pt-0 text-caption ': _vm.isResponsive()
        }},[_vm._v(" "+_vm._s(_vm.getCategory())+" ")]),_c('div',{staticClass:"font-medium pr-2 pt-2 pb-4",class:{
          'text-headline-6': !_vm.isResponsive(),
          'md:text-headline-6 text-subtitle-1': _vm.isResponsive()
        }},[_c('router-link',{class:{
            'overflow-ellipsis': _vm.isResponsive()
          },attrs:{"to":{ name: 'route.article', params: { slug: _vm.getSlug() } },"tag":"a"}},[_vm._v(" "+_vm._s(_vm.getTitle())+" ")])],1),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"rounded-full xl:w-auto xl:h-auto w-8 h-8 xl:my-0 my-2"},[_c('router-link',{attrs:{"to":{
              name: 'route.author',
              params: { username: _vm.getAuthorUsername() }
            },"tag":"a"}},[_c('img',{staticClass:"object-cover w-full h-full rounded-full writter-svg",attrs:{"src":require("@/assets/img/user-default-small.webp"),"alt":""}})])],1),_c('div',{staticClass:"px-4"},[_c('span',{staticClass:"block font-subtitle-2 lg:text-base text-caption"},[_c('router-link',{attrs:{"to":{
                name: 'route.author',
                params: { username: _vm.getAuthorUsername() }
              },"tag":"a"}},[_vm._v(" "+_vm._s(_vm.getAuthorName())+" ")])],1),_c('span',{staticClass:"block text-gray-500 lg:text-base text-caption"},[_vm._v(" "+_vm._s(_vm.getPublishedDate())+" ")])]),_c('div',{staticClass:"flex-grow px-4 py-3"},[(_vm.isLoggedIn() && !_vm.hideBookmark)?_c('span',{staticClass:"inline-block align-middle float-right",on:{"click":function($event){return _vm.changeSaveArticleState()}}},[(!_vm.isBookmarked())?_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V6C7 5.45 7.45 5 8 5H16C16.55 5 17 5.45 17 6V18Z","fill":"black"}})]):_vm._e(),(_vm.isBookmarked())?_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3Z","fill":"black"}})]):_vm._e()]):_vm._e()])])])]:_vm._e(),(_vm.isExcerptDisplay())?[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"rounded-full lg:w-auto lg:h-auto w-8 h-8"},[_c('router-link',{attrs:{"to":{
            name: 'route.author',
            params: { username: _vm.getAuthorUsername() }
          },"tag":"a"}},[_c('img',{staticClass:"object-cover w-full h-full rounded-full writter-svg",attrs:{"src":require("@/assets/img/user-default-small.webp"),"alt":""}})])],1),_c('div',{staticClass:"px-4"},[_c('span',{staticClass:"block font-subtitle-2"},[_c('router-link',{attrs:{"to":{
              name: 'route.author',
              params: { username: _vm.getAuthorUsername() }
            },"tag":"a"}},[_vm._v(" "+_vm._s(_vm.getAuthorName())+" ")])],1),_c('span',{staticClass:"block text-gray-500"},[_vm._v(" "+_vm._s(_vm.getPublishedDate())+" ")])])]),_c('div',{staticClass:"font-medium text-headline-5 pr-2 py-2"},[_c('router-link',{attrs:{"to":{ name: 'route.article', params: { slug: _vm.getSlug() } },"tag":"a"}},[_vm._v(" "+_vm._s(_vm.getTitle())+" ")])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }