const GET_WRITTERS = state => {
  var data = []
  if (state.writters.data !== undefined) {
    state.writters.data.forEach(val => {
      data.push(val)
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_WRITTER_TOTAL_PAGE = state => {
  return state.writters.total_page
}
const SELECT_WRITTER = state => {
  var data = []
  if (state.writters.data !== undefined) {
    state.writters.data.forEach(val => {
      data.push({ id: val.id, writter_title: val.writter_title })
    })
  }
  // console.log('data')
  // console.log(data)
  return data
}
const GET_WRITTER = state => {
  return state.writter
}

const WRITTER_READY = state => {
  return state.ready
}

export default {
  GET_WRITTERS,
  WRITTER_READY,
  GET_WRITTER,
  SELECT_WRITTER,
  GET_WRITTER_TOTAL_PAGE
}
