const SET_ARTICLES = (state, payload) => {
  if (payload.current_page) {
    payload.current_page = parseInt(payload.current_page)
  }
  if (payload.total_page) {
    payload.total_page = parseInt(payload.total_page)
  }
  if (payload.current_page === 1) {
    state.articles = payload
  } else {
    payload.data.forEach((val) => {
      state.articles.push(val)
    })
  }
}
const SET_ARTICLE = (state, payload) => {
  state.article = payload.data
}
const SET_ARTICLE_STATE = (state, payload) => {
  state.ready = payload
}

export default {
  SET_ARTICLE,
  SET_ARTICLES,
  SET_ARTICLE_STATE
}
